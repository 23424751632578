import { memo, useMemo } from 'react';

import { useRouter } from 'next/router';

import { useTranslation } from 'next-export-i18n';

import { useRestaurant } from '@/hooks';
import { QlubLogo } from '@/icons';
import { useLanguage } from '@clubpay/customer-common-module/src/const';
import { useQlubRouter } from '@clubpay/customer-common-module/src/hook/router';
import { isIOS } from '@clubpay/customer-common-module/src/utility/k_user_agent';
import { Stack } from '@mui/material';
import { LanguageSwitcherDrawer } from '@qlub-dev/qlub-kit';

const LanguageSelector = memo(function LanguageSelector() {
  const { pathname, lang, routerPush, theme } = useQlubRouter();
  const router = useRouter();
  const { t } = useTranslation();
  const { languages } = useLanguage();
  const iOS = useMemo(() => isIOS(), []);

  const { data } = useRestaurant();

  const LanguageHandler = (language: string) => {
    routerPush(
      pathname,
      { ...router.query, lang: language },
      { shallow: true },
    );
  };

  return (
    <Stack
      direction='row'
      gap='8px'
      justifyContent='center'
      alignItems='center'
    >
      <LanguageSwitcherDrawer
        languages={languages}
        onChange={LanguageHandler}
        drawerTitle={t('Change Language')}
        lang={lang}
        containerOverride={{
          height: `calc((var(--vh, 1vh) * 100) - ${92 + (iOS ? 80 : 0)}px)`,
        }}
        variant='no-flag'
      />
      {data?.config.hideLogo !== true && (
        <QlubLogo
          color={theme === 'black' ? 'rgb(105, 105, 105)' : undefined}
        />
      )}
    </Stack>
  );
});

export default LanguageSelector;
